/* eslint-disable */
export default function ({ $config }) {
    if (!$config.udimiCodesApiKey) {
        return
    }
    (function(){
        var a = document.createElement('script');
        a.type = 'text/javascript';a.async=1;
        a.src = $config.apiBaseUrl + 'clients/core/' + $config.udimiCodesApiKey + '/script.js';
        var h = document.head || document.getElementsByTagName('head')[0];
        h.appendChild(a);
    })();
}