import dayjs from 'dayjs'

export const state = () => ({
    timeDiff: 0,
    niches: [],
    reviewsCount: 0,
    videoReviewsCount: 0,
    helpMenu: []
})

export const mutations = {
    setTimeDiff (state, payload) {
        state.timeDiff = (new Date()).getTime() - (new Date(payload)).getTime()
    },
    setNiches (state, payload) {
        state.niches = payload || []
    },
    setReviewsCount (state, payload) {
        state.reviewsCount = payload || 0
    },
    setVideoReviewsCount (state, payload) {
        state.videoReviewsCount = payload || 0
    },
    setHelpMenu (state, payload) {
        state.helpMenu = payload
    }
}

export const actions = {}
export const getters = {
    getDateRelative: (state, _getters, rootState) => (dta, $pluralize) => {
        const now = dayjs().valueOf() + state.timeDiff
        const duration = (now - dayjs(dta).valueOf()) / 1000
        if (duration < 15) {
            return 'just now'
        }
        return duration < 60
            ? $pluralize('second', Math.floor(duration)) + ' ago'
            : duration < 60 * 60
                ? $pluralize('minute', Math.floor(duration / 60)) + ' ago'
                : duration < 60 * 60 * 24
                    ? $pluralize('hour', Math.floor(duration / 3600)) + ' ago'
                    : dayjs(dta).format(rootState.constants.local.index.FORUM_DATE_FORMAT)
    }
}
