//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
    name: 'CoAvatar',
    props: {
        src: {
            type: String,
            default: ''
        },
        alt: {
            type: String,
            default: 'Avatar'
        },
        isOnline: {
            type: Boolean,
            default: false
        }
    }
})
