//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const defaultOptions = () => ({
    isConfirm: false,
    isDisabledEscape: false,
    text: '',
    component: null,
    yesLabel: 'Ok',
    noLabel: 'Cancel',
    className: '',
    style: {},
    parentNode: null,
    eventParams: {}
})
export default {
    name: 'CoAppDialog',
    data: () => ({
        options: defaultOptions(),
        originalParentNode: null,
        isOpen: false
    }),
    watch: {
        isOpen: function (newVal) {
            const node = newVal ? this.options.parentNode : this.originalParentNode
            if (node && node !== this.$el.parentNode) {
                this.$el.parentNode.removeChild(this.$el)
                node.appendChild(this.$el)
            }
        }
    },
    mounted () {
        this.originalParentNode = this.$el.parentNode
        document.addEventListener('keyup', this.onKeyPress)
        this.$nuxt.$on('dialog-open', this.open)
    },
    beforeDestroy () {
        document.removeEventListener('keyup', this.onKeyPress)
        this.$nuxt.$off('dialog-open')
    },
    methods: {
        onKeyPress (e) {
            switch (+e.keyCode) {
                // case 13:
                //     this.confirm()
                //     break
                case 27:
                    !this.options.isDisabledEscape && this.cancel()
                    break
            }
        },
        confirm () {
            this.$nuxt.$emit('dialog-confirm', this.options.eventParams)
            this.isOpen = false
        },
        cancel () {
            this.options.isConfirm && this.$nuxt.$emit('dialog-cancel', this.options.eventParams)
            this.isOpen = false
        },
        open (options) {
            this.options = { ...defaultOptions(), ...options }
            this.isOpen = true
        }
    }
}
