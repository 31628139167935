import * as Sentry from '@sentry/browser'
import { AUTH_MODE_DEVELOPMENT } from '~/constants/auth'
import AuthCookie from '~/utils/services/cookies/AuthCookie'
import { getErrorStatusCode } from '~/utils/helpers'

export default function (ctx, inject) {
    const handleErrors = async (error, validateCallback) => {
        const statusCode = getErrorStatusCode(error)
        switch (+statusCode) {
            case 401: {
                if (ctx.$config.authMode === AUTH_MODE_DEVELOPMENT && !process.server) {
                    AuthCookie.remove(ctx)
                }
                ctx.redirect(ctx.$config.siteProtocol + '://' + ctx.$config.outsideHost + '/login')
                break
            }
            case 403: {
                const message = 'Access denied'
                process.server
                    ? ctx.error({ statusCode, message, raw: error })
                    : await ctx.app.$alert(message)
                break
            }
            case 404: {
                process.server
                    ? ctx.error({ statusCode, message: 'Page not found', raw: error })
                    : await ctx.app.$alert('Nothing found')
                break
            }
            case 410: {
                const message = 'You are trying to access expired page'
                process.server
                    ? ctx.error({ statusCode, message, raw: error })
                    : await ctx.app.$alert({ text: message, yesLabel: 'Reload' }).then(() => window.location.reload())
                break
            }
            case 422: {
                const errors = error.response?.data?.errors || {}
                const message = errors && errors[Object.keys(errors)[0]] && Array.isArray(errors[Object.keys(errors)[0]])
                    ? errors[Object.keys(errors)[0]][0]
                    : 'Something went wrong. Please try again later'
                if (process.server) {
                    ctx.error({ statusCode: 400, message, raw: error })
                } else {
                    typeof validateCallback && typeof validateCallback === 'function'
                        ? await validateCallback(message, errors)
                        : await ctx.app.$alert(message)
                }
                break
            }
            default: {
                if (error.toString() === 'Error: Request aborted') {
                    break
                }
                console.error(error)
                Sentry.captureException(error)
            }
        }
        return false
    }
    inject('handleErrors', handleErrors)
}
