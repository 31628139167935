//
//
//
//
//
//
//
//
//

export default {
    name: 'LayDesktopHeader',
    props: {
        isEmpty: {
            type: Boolean,
            default: false
        }
    }
}
