//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import LayDesktopHeader from '~/components/layouts/LayDesktopHeader'
import LayMobileHeader from '~/components/layouts/LayMobileHeader'
import LayMobileMenu from '~/components/layouts/LayMobileMenu'
import { getErrorStatusCode } from '~/utils/helpers'

export default {
    components: {
        LayDesktopHeader,
        LayMobileHeader,
        LayMobileMenu
    },
    layout: 'empty',
    props: {
        error: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        isMenu: false
    }),
    computed: {
        ...mapGetters(['isMobile']),
        isDev () {
            return this.$config.isDev
        }
    },
    methods: {
        getErrorStatusCode,
        menuToggle (val) {
            this.isMenu = val
        }
    }
}
