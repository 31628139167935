//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'CoTopMenu',
    computed: {
        isAffiliateProgramPage () {
            return this.$route.name === 'solo-ads-plink' && this.$route.params.plink === 'udimi-affiliate-program'
        }
    }
}
