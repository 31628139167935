// These options are needed to round to whole numbers if that's what you want.
// minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
// maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)

const moneyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
})

const moneyFormatterInteger = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    style: 'currency',
    currency: 'USD'
})

export default function (_, inject) {
    inject('money', (val, isIntegerResult = false) => isIntegerResult ? moneyFormatterInteger.format(val).replace(/,/g, ' ') : moneyFormatter.format(val).replace(/,/g, ' '))
}
