//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import IndReferral from '~/components/index/IndReferral.vue'
import { APP_RATING, GOOGLE_PLAY_APP_LINK, APPLE_STORE_APP_LINK, TRUST_PILOT_LINK } from '~/constants'

export default {
    name: 'LayDesktopFooter',
    components: {
        IndReferral
    },
    props: {
        affiliate: {
            type: Object,
            default: () => ({})
        }
    },
    data: () => ({
        appRating: APP_RATING,
        appLinks: {
            appleStore: APPLE_STORE_APP_LINK,
            googlePlay: GOOGLE_PLAY_APP_LINK,
            trustPilot: TRUST_PILOT_LINK
        },
        paymentMethods: [
            {
                id: 'visa',
                imagePath: '/assets-outside/img/visa-logo.webp'
            },
            {
                id: 'mastercard',
                imagePath: '/assets-outside/img/mastercard-logo.webp'
            },
            {
                id: 'amex',
                imagePath: '/assets-outside/img/amex-logo.webp'
            },
            {
                id: 'diners',
                imagePath: '/assets-outside/img/diners-logo.webp'
            },
            {
                id: 'paypal',
                imagePath: '/assets-outside/img/paypal-logo.webp'
            },
            {
                id: 'wise',
                imagePath: '/assets-outside/img/wise-logo.webp'
            },
            {
                id: 'stripe',
                imagePath: '/assets-outside/img/stripe-logo.webp'
            }
        ]
    }),
    computed: {
        profileAffiliate () {
            return ['profile-index', 'solodeals-post'].includes(this.$route.name) && this.$store.state.profileAffiliate
        }
    }
}
