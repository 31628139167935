export const state = () => ({
    isMobile: false,
    isShowGreyBox: false,
    memberRedirect: null,
    profileAffiliate: null
})

export const mutations = {
    setDeviceIsMobile (state, payload) {
        state.isMobile = payload
    },
    setIsShowGreyBox (state, payload) {
        state.isShowGreyBox = payload
    },
    setMemberRedirect (state, payload) {
        state.memberRedirect = payload
    },
    setProfileAffiliate (state, payload) {
        state.profileAffiliate = payload
    }
}

export const getters = {
    isMobile: (state) => {
        return state.isMobile
    }
}

export const actions = {
    async nuxtServerInit ({ commit, dispatch }) {
        commit('setDeviceIsMobile', this.$device.isMobile)
        dispatch('cookies/init')
        await dispatch('init')
    },
    init ({ commit }) {
        return this.$api
            .get('guests/app/init')
            .then((res) => {
                commit('app/setTimeDiff', res.data?.now)
                commit('app/setNiches', res.data?.niches)
                commit('setMemberRedirect', res?.data?.redirect)
                commit('constants/setServer', res.data?.constants)
            })
            .catch(err => this.$handleErrors(err))
    }
}
