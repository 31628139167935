window[Symbol.for('fullScreenLoader')] = {
    timeout: null
}
const loader = window[Symbol.for('fullScreenLoader')]

export default function (_, inject) {
    inject('fullScreenLoader', {
        show: (delayMs = 500) => {
            clearTimeout(loader.timeout)
            loader.timeout = setTimeout(() => {
                document.body.classList.add('full-width-view-loader')
            }, delayMs)
        },
        hide: () => {
            clearTimeout(loader.timeout)
            document.body.classList.remove('full-width-view-loader')
        },
        toggle: () => document.body.classList.toggle('full-width-view-loader')
    })
}
