//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api'
import { mapGetters } from 'vuex'
import Mobile from '~/layouts/mobile'
import Desktop from '~/layouts/desktop'
import GoogleAuth from '~/components/elements/ElGoogleAuth.vue'
import CoGreyBox from '~/components/common/CoGreyBox.vue'
import CoConsentUseSite from '~/components/common/CoConsentUseSite.vue'

export default defineComponent({
    components: {
        Mobile,
        Desktop,
        CoGreyBox,
        GoogleAuth,
        CoConsentUseSite
    },
    computed: {
        ...mapGetters(['isMobile'])
    }
})
