//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@vue/composition-api'
import { mapGetters } from 'vuex'

export default defineComponent({
    name: 'CoConsentUseSite',
    data () {
        return {
            isShow: false
        }
    },
    computed: {
        ...mapGetters(['isMobile'])
    },
    mounted () {
        try {
            if (!localStorage.getItem('consentCookieClosed')) {
                this.isShow = true
            }
        } catch (_) {}
    },
    methods: {
        setValue () {
            this.isShow = false
            localStorage.setItem('consentCookieClosed', true)
        }
    }
})
