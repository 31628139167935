import TsourceCookie from '~/utils/services/cookies/TsourceCookie'
import AuthCookie from '~/utils/services/cookies/AuthCookie'
import PromoCookie from '~/utils/services/cookies/PromoCookie'
import UniqueCookie from '~/utils/services/cookies/UniqueCookie'
import { AUTH_MODE_DEVELOPMENT } from '~/constants/auth'
import FullUrlCookie from '~/utils/services/cookies/FullUrlCookie'
import ProfileCookie from '~/utils/services/cookies/ProfileCookie'

export const state = () => ({
    auth: null,
    promo: null,
    tsource: null,
    unique: null,
    fullUrl: null,
    profile: null
})

export const mutations = {
    setAuth (state, payload) {
        state.auth = payload || null
    },
    setPromo (state, payload) {
        state.promo = payload || null
    },
    setTsource (state, payload) {
        state.tsource = payload || null
    },
    setUnique (state, payload) {
        state.unique = payload || null
    },
    setFullUrl (state, payload) {
        state.fullUrl = payload || null
    },
    setProfile (state, payload) {
        state.profile = payload || null
    }
}

export const getters = {
    tsource: state => new TsourceCookie(state.tsource),
    auth: state => new AuthCookie(state.auth),
    promo: state => new PromoCookie(state.promo),
    unique: state => new UniqueCookie(state.unique),
    isUnique: (_state, getters) => uid => uid && !getters.unique.uids.includes(uid),
    fullUrl: state => new FullUrlCookie(state.fullUrl),
    profile: state => new ProfileCookie(state.profile),
    promoData: (_state, getters) => ({
        promo_uid: getters.promo.uid,
        promo_sid: getters.promo.sid,
        promo_url: getters.promo.uid && getters.fullUrl.uid === getters.promo.uid ? getters.fullUrl.referrer : ''
    })
}

export const actions = {
    init ({ commit }) {
        const authValue = this.$config.authMode === AUTH_MODE_DEVELOPMENT
            ? this.$cookies.get(this.$config.authCookieName)
            : this.$legacyCookies.get(this.$config.authCookieName, { doubleHash: true })
        commit('setAuth', authValue)
        commit('setPromo', this.$legacyCookies.get(PromoCookie.cookieName))
        commit('setUnique', this.$legacyCookies.get(UniqueCookie.cookieName))
        commit('setTsource', this.$legacyCookies.get(TsourceCookie.cookieName))
        commit('setFullUrl', this.$legacyCookies.get(FullUrlCookie.cookieName))
        commit('setProfile', this.$legacyCookies.get(ProfileCookie.cookieName))
    },
    async setTsourceCookie ({ commit }, arg) {
        const cookie = await TsourceCookie.setup(this, arg)
        cookie && commit('setTsource', cookie.getEncodedValue())
        return cookie
    },
    removeTsourceCookie ({ commit }) {
        const cookie = TsourceCookie.remove(this)
        cookie && commit('setTsource', cookie.getEncodedValue())
        return cookie
    },
    setUniqueCookie ({ commit }, arg) {
        const cookie = UniqueCookie.setup(this, arg)
        cookie && commit('setUnique', cookie.getEncodedValue())
        return cookie
    },
    setPromoCookie ({ commit }, arg) {
        const cookie = PromoCookie.setup(this, arg)
        cookie && commit('setPromo', cookie.getEncodedValue())
        return cookie
    },
    setFullUrlCookie ({ commit }, arg) {
        const cookie = FullUrlCookie.setup(this, arg)
        cookie && commit('setFullUrl', cookie.getEncodedValue())
        return cookie
    },
    setAuthCookie ({ commit }, arg) {
        const cookie = AuthCookie.setup(this, arg)
        cookie && commit('setAuth', cookie.getEncodedValue())
        return cookie
    },
    setProfileCookie ({ commit }, arg) {
        const cookie = ProfileCookie.setup(this, arg)
        cookie && commit('setProfile', cookie.getEncodedValue())
        return cookie
    },
    async registerAffiliateClick ({ getters, dispatch, _state }, { affiliate, user, req, route }) {
        if (process.server && affiliate && (!user || +affiliate.id !== +user.id)) {
            // get the referrer and sid
            const referrer = req?.headers?.referrer || req?.headers?.referer
            referrer && typeof referrer === 'string' && referrer.substring(0, 512)
            const sid = route?.query?.sid && typeof route.query.sid === 'string'
                ? route.query.sid.substring(0, 20)
                : null

            // set PromoCookie
            const promo = await dispatch('setPromoCookie', { uid: affiliate.uid, sid, user })
            if (promo) {
                await dispatch('setFullUrlCookie', { uid: affiliate.uid, referrer })
            }

            // capture affiliate click
            await this.$api.post('guests/landing/capture-affiliate', {
                uid: affiliate.uid,
                is_unique: getters.isUnique(affiliate.uid),
                referrer,
                sid,
                url: req && req.url
            }).catch(err => this.$handleErrors(err))

            // set UniqueCookie
            await dispatch('setUniqueCookie', { uid: affiliate.uid })
        }
    }
}
