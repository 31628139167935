export default function (to, from, _savedPosition) {
    return new Promise((resolve) => {
        const nuxt = window?.$nuxt
        nuxt.$once('triggerScroll', () => {
            resolve(
                to.path !== from.path
                    ? ({ x: 0, y: 0 })
                    : {}
            )
        })
    })
}
