import { getBaseDomain } from '~/utils/helpers'

export default class AuthCookie {
    id = null
    email = null
    duration = null
    token = null
    fingerprint = false

    constructor (value) {
        value && this.setEncodedValue(value)
    }

    getEncodedValue () {
        return [this.id, this.email, this.duration, { saved_key: this.token, fpToken2: this.fingerprint }]
    }

    setEncodedValue (value) {
        this.id = value?.[0] || null
        this.email = value?.[1] || null
        this.duration = value?.[2] || null
        this.token = !!value?.[3]?.saved_key || false
        this.fingerprint = value?.[3]?.fpToken2
    }

    getSettings (ctx) {
        return {
            path: '/',
            domain: getBaseDomain(ctx.$config.siteHost),
            sameSite: 'lax',
            secure: ctx.$config.siteProtocol === 'https'
        }
    }

    static setup (ctx, arg) {
        // setup in dev mode only
        if (!arg.token) {
            return false
        }
        const cookie = new this()
        cookie.token = arg.token
        cookie.fingerprint = arg.fingerprint || null
        cookie.id = arg.id || null
        cookie.email = arg.email || null
        cookie.duration = arg.duration || null
        ctx.$cookies.set(ctx.$config.authCookieName, cookie.getEncodedValue(), {
            maxAge: 3600 * 24 * 30,
            ...cookie.getSettings(ctx)
        })
        return cookie
    }

    static remove (ctx) {
        ctx.$cookies.remove(ctx.$config.authCookieName, (new this()).getSettings(ctx))
    }
}
