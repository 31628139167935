import Vue from 'vue'

const mixin = {
    computed: {
        constants () {
            return this.$store.state.constants
        }
    }
}

Vue.mixin(mixin)
