import { getBaseDomain } from '~/utils/helpers'
import { TSOURCE_DIRECT } from '~/constants/tsource'

export default class TsourceCookie {
    uid = null
    date = null
    optin = null
    visited = []

    constructor (value) {
        value && this.setEncodedValue(value)
    }

    static get cookieName () {
        return 'udimi-ts2'
    }

    getEncodedValue () {
        return [this.uid, this.date, this.optin, this.visited.join(',')].join('|')
    }

    setEncodedValue (value) {
        const data = value?.split('|') || []
        this.uid = data?.[0] || null
        this.date = data?.[1] || null
        this.optin = data?.[2] || null
        this.visited = data?.[3] ? data?.[3].split(',') : []
    }

    static async setup (ctx, arg) {
        if (!arg.uid || !ctx.$legacyCookies) {
            return false
        }
        const cookie = new this()
        cookie.setEncodedValue(ctx.$legacyCookies.get(cookie.constructor.cookieName))

        // capture direct click, only if there is no captured other clicks
        if (cookie.uid && arg.uid === TSOURCE_DIRECT) {
            return false
        }

        // set payload
        cookie.uid = arg.uid
        cookie.optin = arg.optin || null
        // YYYY-MM-DD
        cookie.date = Intl.DateTimeFormat('sv-SE', { timeZone: 'UTC' }).format(new Date())
        if (!cookie.visited.includes(arg.uid)) {
            // update visited list
            cookie.visited.push(arg.uid)
            // increment the counter
            await ctx.$api.post('/guests/landing/capture-visit', { uid: arg.uid }).catch(() => false)
            // .catch(err => ctx.$handleErrors(err))
        }

        // set the cookie
        ctx.$legacyCookies.set(cookie.constructor.cookieName, cookie.getEncodedValue(), {
            maxAge: 3600 * 24 * 30,
            domain: getBaseDomain(ctx.$config.siteHost)
        })
        return cookie
    }

    static remove (ctx) {
        const cookie = new this()
        // remove without legacy cookies b/c we do not need encryption here
        ctx.$cookies.remove(cookie.constructor.cookieName, {
            domain: getBaseDomain(ctx.$config.siteHost)
        })
        return cookie
    }
}
