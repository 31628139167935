import { getBaseDomain } from '~/utils/helpers'

// YYYY-MM-DD
const dateToFormat = date => Intl.DateTimeFormat('sv-SE', { timeZone: 'UTC' }).format(date)

const MAX_LENGTH = 25

export default class ProfileCookie {
    users = []
    date = dateToFormat(new Date())

    constructor (value) {
        value && this.setEncodedValue(value)
    }

    static get cookieName () {
        return 'udimi-gpv'
    }

    getEncodedValue () {
        return {
            date: this.date,
            users: this.users
        }
    }

    setEncodedValue (value) {
        if (!value || !value.date || !Array.isArray(value.users) || value.date !== dateToFormat(new Date())) {
            value = {
                date: dateToFormat(new Date()),
                users: []
            }
        }
        this.users = value.users
        this.date = value.date
    }

    static setup (ctx, arg) {
        if (!arg.uid) {
            return false
        }
        const cookie = new this()
        cookie.setEncodedValue(ctx.$cookies.get(cookie.constructor.cookieName))

        // do not set the cookie if uid is already in the list
        if (cookie.users.includes(arg.uid)) {
            return false
        }

        // limit the length
        if (cookie.users.length >= MAX_LENGTH) {
            cookie.users.splice(0, cookie.users.length - MAX_LENGTH + 1)
        }

        // add new value
        cookie.users.push(arg.uid)

        // set the cookie
        ctx.$cookies.set(cookie.constructor.cookieName, cookie.getEncodedValue(), {
            path: '/',
            maxAge: 3600 * 24 * 180,
            domain: getBaseDomain(ctx.$config.siteHost)
        })

        return cookie
    }
}
