//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@vue/composition-api'
import { keysToCamel } from '~/utils/helpers'

export default defineComponent({
    name: 'IndReferral',
    props: {
        data: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        affiliate () {
            return keysToCamel(this.data)
        }
    }
})
