const middleware = {}

middleware['auth_login'] = require('../middleware/auth_login.js')
middleware['auth_login'] = middleware['auth_login'].default || middleware['auth_login']

middleware['auth_reload'] = require('../middleware/auth_reload.js')
middleware['auth_reload'] = middleware['auth_reload'].default || middleware['auth_reload']

middleware['forum'] = require('../middleware/forum.js')
middleware['forum'] = middleware['forum'].default || middleware['forum']

middleware['redirect_m1'] = require('../middleware/redirect_m1.js')
middleware['redirect_m1'] = middleware['redirect_m1'].default || middleware['redirect_m1']

middleware['route_validation'] = require('../middleware/route_validation.js')
middleware['route_validation'] = middleware['route_validation'].default || middleware['route_validation']

export default middleware
