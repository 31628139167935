//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'LayMobileHeader',
    data: () => ({
        initParams: {
            previousTop: 0,
            cumulativePreviousTop: 0,
            headerHeight: 60
        }
    }),
    mounted () {
        if (!this.$el.classList.contains('m-fixed')) {
            window.addEventListener('scroll', this.scroll)
        }
    },
    beforeDestroy () {
        window.removeEventListener('scroll', this.scroll)
    },
    methods: {
        scroll () {
            const currentTop = window.scrollY > 0 ? window.scrollY : 0

            this.initParams.cumulativePreviousTop += this.initParams.previousTop - currentTop
            if (this.initParams.cumulativePreviousTop > 0) {
                this.initParams.cumulativePreviousTop = 0
            } else if (this.initParams.cumulativePreviousTop < -this.initParams.headerHeight) {
                this.initParams.cumulativePreviousTop = -this.initParams.headerHeight
            }

            const headerEl = document.getElementsByClassName('js-app-mobile-layout-header')[0]

            if (headerEl) {
                headerEl.style = `top: ${this.initParams.cumulativePreviousTop}px`
            }

            this.initParams.previousTop = currentTop
        }
    }
}
