//
//
//
//

import LayDesktopFooter from '~/components/layouts/LayDesktopFooter.vue'

export default {
    name: 'LayMobileFooter',
    components: {
        LayDesktopFooter
    },
    props: {
        affiliate: {
            type: Object,
            default: () => ({})
        }
    }
}
