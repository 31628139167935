/* eslint-disable */
export default function ({ $config }) {
    if ($config.isDev) {
        return
    }

    const initGTMOnEvent = function () {
        initGTM()
        document.removeEventListener('scroll', initGTMOnEvent)
        document.removeEventListener('mousemove', initGTMOnEvent)
        document.removeEventListener('touchstart', initGTMOnEvent)
    }

    const initGTM = function () {
        if (window.gtmDidInit) {
            return false
        }
        window.gtmDidInit = true // flag to ensure script does not get added to DOM more than once.

        // Google tag (gtag.js)
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.async = true
        script.src = 'https://www.googletagmanager.com/gtag/js?id=G-L995PENJDP'
        document.head.appendChild(script)

        window.dataLayer = window.dataLayer || [];
        function gtag () {
            dataLayer.push(arguments);
        }
        gtag('js', new Date());
        gtag('config', 'G-L995PENJDP');

        // Google Tag Manager
        (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
            var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', 'GTM-TRJXQTV');
        // End Google Tag Manager
    }

    document.addEventListener('scroll', initGTMOnEvent)
    document.addEventListener('mousemove', initGTMOnEvent)
    document.addEventListener('touchstart', initGTMOnEvent)

    window.addEventListener('load', () => {
        setTimeout(initGTM, 3000)
    })
}