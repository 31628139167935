import setCookie from 'set-cookie-parser'

export default class ApiInterface {
    constructor (ctx) {
        this.ctx = ctx
    }

    get (_url, _params, _options) {
        return Promise.resolve(false)
    }

    post (_url, _params, _options) {
        return Promise.resolve(false)
    }

    put (_url, _params, _options) {
        return Promise.resolve(false)
    }

    buildQueryString (params, namespace) {
        let qs = namespace ? '' : '?'
        for (const k in params) {
            if (!Object.prototype.hasOwnProperty.call(params, k)) {
                continue
            }
            const val = params[k]
            if (typeof val === 'object') {
                qs += this.buildQueryString(val, k)
            } else if (val !== null && typeof val !== 'undefined') {
                qs += (namespace ? `${namespace}[${k}]` : k) + '=' + encodeURIComponent(val) + '&'
            }
        }
        if (!namespace) {
            qs = qs.replace(/&$/, '')
        }
        return qs
    }

    proxyCookies (cookies) {
        const parsed = setCookie.parse(setCookie.splitCookiesString(cookies))
        parsed.forEach((cookie) => {
            const { name, value, ...options } = cookie
            this.ctx.$cookies.set(name, value, options)
        })
    }
}
