import { extend } from 'dayjs'
import utc from 'dayjs/plugin/utc'

extend(utc)

export default function ({ $dayjs }, inject) {
    inject('dayjsUTC', function (...args) {
        return $dayjs(...args).utc()
    })
}
