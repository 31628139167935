import Vue from 'vue'
import VTooltip from 'v-tooltip'
// https://www.npmjs.com/package/v-tooltip

export default () => {
    Vue.use(VTooltip, {
        defaultClass: 'udimi-tooltip',
        autoHide: false,
        defaultDelay: { show: 300, hide: 0 }
    })
}
