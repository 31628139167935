export const AUTH_MODE_PRODUCTION = 'production'
export const AUTH_MODE_DEVELOPMENT = 'development'

export const REDIRECT_URLS = {
    offer: '/oto',
    mypage: '/my',
    solos: '/solos',
    search: '/buy-solo-ads',
    affiliates: '/affiliates'
}
