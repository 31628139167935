export default async (_, inject) => {
    inject('fingerprint', await new Promise((resolve, reject) => {
        try {
            import('@fingerprintjs/fingerprintjs')
                .then((res) => {
                    const Fingerprint = res.default
                    new Fingerprint({
                        excludePixelRatio: true,
                        excludeAvailableScreenResolution: true,
                        excludeScreenResolution: true,
                        excludeCanvas: true
                    }).get(result => resolve(result))
                })
        } catch (err) {
            reject(err)
        }
    }))
}
