import { reactive } from '@vue/composition-api'
import config from './config'

const state = reactive({
    clientId: null,
    popupType: 'CODE',
    prompt: false,
    autoLogin: false,
    idConfiguration: null,
    buttonConfig: config.defaultButtonConfig,
    callback: () => {},
    error: null
})

export default state

export const libraryState = reactive({
    apiLoaded: false,
    apiLoadIntitited: false
})

export const setState = (options) => {
    options.clientId && (state.clientId = options.clientId)
    options.popupType && (state.popupType = options.popupType)
    options.prompt !== undefined && (state.prompt = options.prompt)
    options.autoLogin !== undefined && (state.autoLogin = options.autoLogin)
    options.idConfiguration && (state.idConfiguration = options.idConfiguration)
    options.buttonConfig && (state.buttonConfig = options.buttonConfig)
    options.callback && (state.callback = options.callback)
}
