import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4afc707e = () => interopDefault(import('../pages/affiliate.vue' /* webpackChunkName: "pages/affiliate" */))
const _7962d182 = () => interopDefault(import('../pages/affiliates.vue' /* webpackChunkName: "pages/affiliates" */))
const _4d7e8f1c = () => interopDefault(import('../pages/awards.vue' /* webpackChunkName: "pages/awards" */))
const _34d63f36 = () => interopDefault(import('../pages/buy-solo-ads.vue' /* webpackChunkName: "pages/buy-solo-ads" */))
const _65e271b0 = () => interopDefault(import('../pages/buy-solo-ads/index.vue' /* webpackChunkName: "pages/buy-solo-ads/index" */))
const _0c3976d6 = () => interopDefault(import('../pages/buy-solo-ads/niche/_slug.vue' /* webpackChunkName: "pages/buy-solo-ads/niche/_slug" */))
const _45d3d425 = () => interopDefault(import('../pages/dev-login.vue' /* webpackChunkName: "pages/dev-login" */))
const _65120134 = () => interopDefault(import('../pages/filter.vue' /* webpackChunkName: "pages/filter" */))
const _48c78ea2 = () => interopDefault(import('../pages/forgot/index.vue' /* webpackChunkName: "pages/forgot/index" */))
const _17f158d6 = () => interopDefault(import('../pages/forum.vue' /* webpackChunkName: "pages/forum" */))
const _15ca1658 = () => interopDefault(import('../pages/forum/index.vue' /* webpackChunkName: "pages/forum/index" */))
const _660edd10 = () => interopDefault(import('../pages/forum/comment/_id.vue' /* webpackChunkName: "pages/forum/comment/_id" */))
const _ca5565da = () => interopDefault(import('../pages/forum/_section/index.vue' /* webpackChunkName: "pages/forum/_section/index" */))
const _53ecf006 = () => interopDefault(import('../pages/forum/_section/_idSlug.vue' /* webpackChunkName: "pages/forum/_section/_idSlug" */))
const _35631d06 = () => interopDefault(import('../pages/help.vue' /* webpackChunkName: "pages/help" */))
const _89a5b380 = () => interopDefault(import('../pages/help/index.vue' /* webpackChunkName: "pages/help/index" */))
const _2505193d = () => interopDefault(import('../pages/help/support.vue' /* webpackChunkName: "pages/help/support" */))
const _320d814a = () => interopDefault(import('../pages/help/a/_plink.vue' /* webpackChunkName: "pages/help/a/_plink" */))
const _0d64534b = () => interopDefault(import('../pages/help/_category.vue' /* webpackChunkName: "pages/help/_category" */))
const _7f55a362 = () => interopDefault(import('../pages/help/_unregistered/_plink.vue' /* webpackChunkName: "pages/help/_unregistered/_plink" */))
const _135151dd = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _e589ba32 = () => interopDefault(import('../pages/logo.vue' /* webpackChunkName: "pages/logo" */))
const _1dd4db1c = () => interopDefault(import('../pages/logos.vue' /* webpackChunkName: "pages/logos" */))
const _0c2799f3 = () => interopDefault(import('../pages/prime.vue' /* webpackChunkName: "pages/prime" */))
const _81babc40 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _7481b45c = () => interopDefault(import('../pages/reviews/index.vue' /* webpackChunkName: "pages/reviews/index" */))
const _d169f938 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _59e09e62 = () => interopDefault(import('../pages/search/_unregistered.vue' /* webpackChunkName: "pages/search/_unregistered" */))
const _b99a5258 = () => interopDefault(import('../pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _4c645afe = () => interopDefault(import('../pages/solo-ads/index.vue' /* webpackChunkName: "pages/solo-ads/index" */))
const _64fb8608 = () => interopDefault(import('../pages/solodeals.vue' /* webpackChunkName: "pages/solodeals" */))
const _4054167f = () => interopDefault(import('../pages/solodeals/index.vue' /* webpackChunkName: "pages/solodeals/index" */))
const _1c7ce914 = () => interopDefault(import('../pages/solodeals/price.vue' /* webpackChunkName: "pages/solodeals/price" */))
const _75ed92cb = () => interopDefault(import('../pages/solodeals/search.vue' /* webpackChunkName: "pages/solodeals/search" */))
const _ba0bbea8 = () => interopDefault(import('../pages/solodeals/_post.vue' /* webpackChunkName: "pages/solodeals/_post" */))
const _5ff25364 = () => interopDefault(import('../pages/test.vue' /* webpackChunkName: "pages/test" */))
const _b48442ca = () => interopDefault(import('../pages/test-apple.vue' /* webpackChunkName: "pages/test-apple" */))
const _4b9b2a04 = () => interopDefault(import('../pages/test-cases.vue' /* webpackChunkName: "pages/test-cases" */))
const _0a99e792 = () => interopDefault(import('../pages/test-cases/page1.vue' /* webpackChunkName: "pages/test-cases/page1" */))
const _0a7db890 = () => interopDefault(import('../pages/test-cases/page2.vue' /* webpackChunkName: "pages/test-cases/page2" */))
const _52ad3c05 = () => interopDefault(import('../pages/test-owl.vue' /* webpackChunkName: "pages/test-owl" */))
const _98b16eee = () => interopDefault(import('../pages/udimi-reviews-video/index.vue' /* webpackChunkName: "pages/udimi-reviews-video/index" */))
const _1ed62e7e = () => interopDefault(import('../pages/competition/hall-of-fame.vue' /* webpackChunkName: "pages/competition/hall-of-fame" */))
const _6e606c13 = () => interopDefault(import('../pages/notifications/instantunsubscribe.vue' /* webpackChunkName: "pages/notifications/instantunsubscribe" */))
const _5909b226 = () => interopDefault(import('../pages/notifications/settings.vue' /* webpackChunkName: "pages/notifications/settings" */))
const _2d7fc5a7 = () => interopDefault(import('../pages/profile/custom-route/recommends.vue' /* webpackChunkName: "pages/profile/custom-route/recommends" */))
const _49a9aec6 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _7f15bcfe = () => interopDefault(import('../pages/competition/movers-and-shakers/_month.vue' /* webpackChunkName: "pages/competition/movers-and-shakers/_month" */))
const _c3fc34cc = () => interopDefault(import('../pages/feeds/post/_uid.vue' /* webpackChunkName: "pages/feeds/post/_uid" */))
const _a5555e1e = () => interopDefault(import('../pages/articles/_plink.vue' /* webpackChunkName: "pages/articles/_plink" */))
const _24d573f6 = () => interopDefault(import('../pages/ratings/_month.vue' /* webpackChunkName: "pages/ratings/_month" */))
const _6e08d7d7 = () => interopDefault(import('../pages/restore/_token.vue' /* webpackChunkName: "pages/restore/_token" */))
const _89a320c8 = () => interopDefault(import('../pages/reviews/_permalink.vue' /* webpackChunkName: "pages/reviews/_permalink" */))
const _7333befd = () => interopDefault(import('../pages/share/_uid.vue' /* webpackChunkName: "pages/share/_uid" */))
const _0263b34c = () => interopDefault(import('../pages/solo-ads/_plink.vue' /* webpackChunkName: "pages/solo-ads/_plink" */))
const _71ab288c = () => interopDefault(import('../pages/token/_token.vue' /* webpackChunkName: "pages/token/_token" */))
const _1c688d25 = () => interopDefault(import('../pages/udimi-reviews-video/_page.vue' /* webpackChunkName: "pages/udimi-reviews-video/_page" */))
const _51e55480 = () => interopDefault(import('../pages/solo/_uid/_anySubRoute.vue' /* webpackChunkName: "pages/solo/_uid/_anySubRoute" */))
const _703aadb1 = () => interopDefault(import('../pages/_unregistered/index.vue' /* webpackChunkName: "pages/_unregistered/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/affiliate",
    component: _4afc707e,
    name: "affiliate"
  }, {
    path: "/affiliates",
    component: _7962d182,
    name: "affiliates"
  }, {
    path: "/awards",
    component: _4d7e8f1c,
    name: "awards"
  }, {
    path: "/buy-solo-ads",
    component: _34d63f36,
    children: [{
      path: "",
      component: _65e271b0,
      name: "buy-solo-ads"
    }, {
      path: "niche/:slug?",
      component: _0c3976d6,
      name: "buy-solo-ads-niche-slug"
    }]
  }, {
    path: "/dev-login",
    component: _45d3d425,
    name: "dev-login"
  }, {
    path: "/filter",
    component: _65120134,
    name: "filter"
  }, {
    path: "/forgot",
    component: _48c78ea2,
    name: "forgot"
  }, {
    path: "/forum",
    component: _17f158d6,
    children: [{
      path: "",
      component: _15ca1658,
      name: "forum"
    }, {
      path: "comment/:id?",
      component: _660edd10,
      name: "forum-comment-id"
    }, {
      path: ":section",
      component: _ca5565da,
      name: "forum-section"
    }, {
      path: ":section/:idSlug",
      component: _53ecf006,
      name: "forum-section-idSlug"
    }]
  }, {
    path: "/help",
    component: _35631d06,
    children: [{
      path: "",
      component: _89a5b380,
      name: "help"
    }, {
      path: "support",
      component: _2505193d,
      name: "help-support"
    }, {
      path: "a/:plink?",
      component: _320d814a,
      name: "help-a-plink"
    }, {
      path: ":category",
      component: _0d64534b,
      name: "help-category"
    }, {
      path: ":unregistered/:plink?",
      component: _7f55a362,
      name: "help-unregistered-plink"
    }]
  }, {
    path: "/login",
    component: _135151dd,
    name: "login"
  }, {
    path: "/logo",
    component: _e589ba32,
    name: "logo"
  }, {
    path: "/logos",
    component: _1dd4db1c,
    name: "logos"
  }, {
    path: "/prime",
    component: _0c2799f3,
    name: "prime"
  }, {
    path: "/profile",
    component: _81babc40,
    name: "profile"
  }, {
    path: "/reviews",
    component: _7481b45c,
    name: "reviews"
  }, {
    path: "/search",
    component: _d169f938,
    name: "search",
    children: [{
      path: ":unregistered?",
      component: _59e09e62,
      name: "search-unregistered"
    }]
  }, {
    path: "/signup",
    component: _b99a5258,
    name: "signup"
  }, {
    path: "/solo-ads",
    component: _4c645afe,
    name: "solo-ads"
  }, {
    path: "/solodeals",
    component: _64fb8608,
    children: [{
      path: "",
      component: _4054167f,
      name: "solodeals"
    }, {
      path: "price",
      component: _1c7ce914,
      name: "solodeals-price"
    }, {
      path: "search",
      component: _75ed92cb,
      name: "solodeals-search"
    }, {
      path: ":post",
      component: _ba0bbea8,
      name: "solodeals-post"
    }]
  }, {
    path: "/test",
    component: _5ff25364,
    name: "test"
  }, {
    path: "/test-apple",
    component: _b48442ca,
    name: "test-apple"
  }, {
    path: "/test-cases",
    component: _4b9b2a04,
    name: "test-cases",
    children: [{
      path: "page1",
      component: _0a99e792,
      name: "test-cases-page1"
    }, {
      path: "page2",
      component: _0a7db890,
      name: "test-cases-page2"
    }]
  }, {
    path: "/test-owl",
    component: _52ad3c05,
    name: "test-owl"
  }, {
    path: "/udimi-reviews-video",
    component: _98b16eee,
    name: "udimi-reviews-video"
  }, {
    path: "/competition/hall-of-fame",
    component: _1ed62e7e,
    name: "competition-hall-of-fame"
  }, {
    path: "/notifications/instantunsubscribe",
    component: _6e606c13,
    name: "notifications-instantunsubscribe"
  }, {
    path: "/notifications/settings",
    component: _5909b226,
    name: "notifications-settings"
  }, {
    path: "/profile/custom-route/recommends",
    component: _2d7fc5a7,
    name: "profile-custom-route-recommends"
  }, {
    path: "/",
    component: _49a9aec6,
    name: "index"
  }, {
    path: "/competition/movers-and-shakers/:month?",
    component: _7f15bcfe,
    name: "competition-movers-and-shakers-month"
  }, {
    path: "/feeds/post/:uid?",
    component: _c3fc34cc,
    name: "feeds-post-uid"
  }, {
    path: "/articles/:plink?",
    component: _a5555e1e,
    name: "articles-plink"
  }, {
    path: "/ratings/:month?",
    component: _24d573f6,
    name: "ratings-month"
  }, {
    path: "/restore/:token?",
    component: _6e08d7d7,
    name: "restore-token"
  }, {
    path: "/reviews/:permalink",
    component: _89a320c8,
    name: "reviews-permalink"
  }, {
    path: "/share/:uid?",
    component: _7333befd,
    name: "share-uid"
  }, {
    path: "/solo-ads/:plink?",
    component: _0263b34c,
    name: "solo-ads-plink"
  }, {
    path: "/token/:token?",
    component: _71ab288c,
    name: "token-token"
  }, {
    path: "/udimi-reviews-video/:page?",
    component: _1c688d25,
    name: "udimi-reviews-video-page"
  }, {
    path: "/solo/:uid?/:anySubRoute?",
    component: _51e55480,
    name: "solo-uid-anySubRoute"
  }, {
    path: "/:unregistered",
    component: _703aadb1,
    name: "unregistered"
  }, {
    path: "/u/:hash",
    component: _6e606c13,
    name: "u-hash"
  }, {
    path: "/u/settings/:hash",
    component: _5909b226,
    name: "u-settings-hash"
  }, {
    path: "/a/:uid",
    component: _49a9aec6,
    name: "affiliate-regular"
  }, {
    path: "/prime/:uid([a-zA-Z0-9]{5})",
    component: _49a9aec6,
    name: "affiliate-prime"
  }, {
    path: "/p/:uid_profile",
    component: _81babc40,
    name: "profile-index"
  }, {
    path: "/p/:uid_profile/recommends",
    component: _2d7fc5a7,
    name: "profile-recommends"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
