export const CoAppDialog = () => import('../../components/common/CoAppDialog.vue' /* webpackChunkName: "components/co-app-dialog" */).then(c => wrapFunctional(c.default || c))
export const CoArticle = () => import('../../components/common/CoArticle.vue' /* webpackChunkName: "components/co-article" */).then(c => wrapFunctional(c.default || c))
export const CoAvatar = () => import('../../components/common/CoAvatar.vue' /* webpackChunkName: "components/co-avatar" */).then(c => wrapFunctional(c.default || c))
export const CoClickTable = () => import('../../components/common/CoClickTable.vue' /* webpackChunkName: "components/co-click-table" */).then(c => wrapFunctional(c.default || c))
export const CoClickTableMobile = () => import('../../components/common/CoClickTableMobile.vue' /* webpackChunkName: "components/co-click-table-mobile" */).then(c => wrapFunctional(c.default || c))
export const CoConsentUseSite = () => import('../../components/common/CoConsentUseSite.vue' /* webpackChunkName: "components/co-consent-use-site" */).then(c => wrapFunctional(c.default || c))
export const CoFullscreenVideo = () => import('../../components/common/CoFullscreenVideo.vue' /* webpackChunkName: "components/co-fullscreen-video" */).then(c => wrapFunctional(c.default || c))
export const CoGreyBox = () => import('../../components/common/CoGreyBox.vue' /* webpackChunkName: "components/co-grey-box" */).then(c => wrapFunctional(c.default || c))
export const CoLocationMap = () => import('../../components/common/CoLocationMap.vue' /* webpackChunkName: "components/co-location-map" */).then(c => wrapFunctional(c.default || c))
export const CoLoginForm = () => import('../../components/common/CoLoginForm.vue' /* webpackChunkName: "components/co-login-form" */).then(c => wrapFunctional(c.default || c))
export const CoProfileLink = () => import('../../components/common/CoProfileLink.vue' /* webpackChunkName: "components/co-profile-link" */).then(c => wrapFunctional(c.default || c))
export const CoRateStars = () => import('../../components/common/CoRateStars.vue' /* webpackChunkName: "components/co-rate-stars" */).then(c => wrapFunctional(c.default || c))
export const CoRecaptcha = () => import('../../components/common/CoRecaptcha.vue' /* webpackChunkName: "components/co-recaptcha" */).then(c => wrapFunctional(c.default || c))
export const CoReviewBox = () => import('../../components/common/CoReviewBox.vue' /* webpackChunkName: "components/co-review-box" */).then(c => wrapFunctional(c.default || c))
export const CoSearchBox = () => import('../../components/common/CoSearchBox.vue' /* webpackChunkName: "components/co-search-box" */).then(c => wrapFunctional(c.default || c))
export const CoSignupForm = () => import('../../components/common/CoSignupForm.vue' /* webpackChunkName: "components/co-signup-form" */).then(c => wrapFunctional(c.default || c))
export const CoSortingDropdown = () => import('../../components/common/CoSortingDropdown.vue' /* webpackChunkName: "components/co-sorting-dropdown" */).then(c => wrapFunctional(c.default || c))
export const CoSwiper = () => import('../../components/common/CoSwiper.vue' /* webpackChunkName: "components/co-swiper" */).then(c => wrapFunctional(c.default || c))
export const CoSwiperSlide = () => import('../../components/common/CoSwiperSlide.vue' /* webpackChunkName: "components/co-swiper-slide" */).then(c => wrapFunctional(c.default || c))
export const CoTabs = () => import('../../components/common/CoTabs.vue' /* webpackChunkName: "components/co-tabs" */).then(c => wrapFunctional(c.default || c))
export const CoTopLogo = () => import('../../components/common/CoTopLogo.vue' /* webpackChunkName: "components/co-top-logo" */).then(c => wrapFunctional(c.default || c))
export const CoTopMenu = () => import('../../components/common/CoTopMenu.vue' /* webpackChunkName: "components/co-top-menu" */).then(c => wrapFunctional(c.default || c))
export const CoUserBox = () => import('../../components/common/CoUserBox.vue' /* webpackChunkName: "components/co-user-box" */).then(c => wrapFunctional(c.default || c))
export const CoUserBoxLink = () => import('../../components/common/CoUserBoxLink.vue' /* webpackChunkName: "components/co-user-box-link" */).then(c => wrapFunctional(c.default || c))
export const CoVideoPlayer = () => import('../../components/common/CoVideoPlayer.vue' /* webpackChunkName: "components/co-video-player" */).then(c => wrapFunctional(c.default || c))
export const CoVideoPlayerTranscription = () => import('../../components/common/CoVideoPlayerTranscription.vue' /* webpackChunkName: "components/co-video-player-transcription" */).then(c => wrapFunctional(c.default || c))
export const ElButtonWait = () => import('../../components/elements/ElButtonWait.vue' /* webpackChunkName: "components/el-button-wait" */).then(c => wrapFunctional(c.default || c))
export const ElCheckbox = () => import('../../components/elements/ElCheckbox.vue' /* webpackChunkName: "components/el-checkbox" */).then(c => wrapFunctional(c.default || c))
export const ElCheckboxColor = () => import('../../components/elements/ElCheckboxColor.vue' /* webpackChunkName: "components/el-checkbox-color" */).then(c => wrapFunctional(c.default || c))
export const ElCheckboxSlider = () => import('../../components/elements/ElCheckboxSlider.vue' /* webpackChunkName: "components/el-checkbox-slider" */).then(c => wrapFunctional(c.default || c))
export const ElCheckboxSliderBig = () => import('../../components/elements/ElCheckboxSliderBig.vue' /* webpackChunkName: "components/el-checkbox-slider-big" */).then(c => wrapFunctional(c.default || c))
export const ElColorPalette = () => import('../../components/elements/ElColorPalette.vue' /* webpackChunkName: "components/el-color-palette" */).then(c => wrapFunctional(c.default || c))
export const ElDropdown = () => import('../../components/elements/ElDropdown.vue' /* webpackChunkName: "components/el-dropdown" */).then(c => wrapFunctional(c.default || c))
export const ElErrorText = () => import('../../components/elements/ElErrorText.vue' /* webpackChunkName: "components/el-error-text" */).then(c => wrapFunctional(c.default || c))
export const ElGoogleAuth = () => import('../../components/elements/ElGoogleAuth.vue' /* webpackChunkName: "components/el-google-auth" */).then(c => wrapFunctional(c.default || c))
export const ElInfiniteScroll = () => import('../../components/elements/ElInfiniteScroll.vue' /* webpackChunkName: "components/el-infinite-scroll" */).then(c => wrapFunctional(c.default || c))
export const ElInfoBox = () => import('../../components/elements/ElInfoBox.vue' /* webpackChunkName: "components/el-info-box" */).then(c => wrapFunctional(c.default || c))
export const ElInputSearch = () => import('../../components/elements/ElInputSearch.vue' /* webpackChunkName: "components/el-input-search" */).then(c => wrapFunctional(c.default || c))
export const ElInputWithFrame = () => import('../../components/elements/ElInputWithFrame.vue' /* webpackChunkName: "components/el-input-with-frame" */).then(c => wrapFunctional(c.default || c))
export const ElPagination = () => import('../../components/elements/ElPagination.vue' /* webpackChunkName: "components/el-pagination" */).then(c => wrapFunctional(c.default || c))
export const ElRange = () => import('../../components/elements/ElRange.vue' /* webpackChunkName: "components/el-range" */).then(c => wrapFunctional(c.default || c))
export const ElRateStars = () => import('../../components/elements/ElRateStars.vue' /* webpackChunkName: "components/el-rate-stars" */).then(c => wrapFunctional(c.default || c))
export const ElTable = () => import('../../components/elements/ElTable.vue' /* webpackChunkName: "components/el-table" */).then(c => wrapFunctional(c.default || c))
export const ElUdimiLink = () => import('../../components/elements/ElUdimiLink.vue' /* webpackChunkName: "components/el-udimi-link" */).then(c => wrapFunctional(c.default || c))
export const IndAffiliatesMakeMoreMoney = () => import('../../components/index/IndAffiliatesMakeMoreMoney.vue' /* webpackChunkName: "components/ind-affiliates-make-more-money" */).then(c => wrapFunctional(c.default || c))
export const IndApplication = () => import('../../components/index/IndApplication.vue' /* webpackChunkName: "components/ind-application" */).then(c => wrapFunctional(c.default || c))
export const IndAward = () => import('../../components/index/IndAward.vue' /* webpackChunkName: "components/ind-award" */).then(c => wrapFunctional(c.default || c))
export const IndBlocks = () => import('../../components/index/IndBlocks.vue' /* webpackChunkName: "components/ind-blocks" */).then(c => wrapFunctional(c.default || c))
export const IndIdVerifiedSellers = () => import('../../components/index/IndIdVerifiedSellers.vue' /* webpackChunkName: "components/ind-id-verified-sellers" */).then(c => wrapFunctional(c.default || c))
export const IndLiveReviewCard = () => import('../../components/index/IndLiveReviewCard.vue' /* webpackChunkName: "components/ind-live-review-card" */).then(c => wrapFunctional(c.default || c))
export const IndLiveReviews = () => import('../../components/index/IndLiveReviews.vue' /* webpackChunkName: "components/ind-live-reviews" */).then(c => wrapFunctional(c.default || c))
export const IndMakeMoreMoney = () => import('../../components/index/IndMakeMoreMoney.vue' /* webpackChunkName: "components/ind-make-more-money" */).then(c => wrapFunctional(c.default || c))
export const IndPersonalTouch = () => import('../../components/index/IndPersonalTouch.vue' /* webpackChunkName: "components/ind-personal-touch" */).then(c => wrapFunctional(c.default || c))
export const IndReferral = () => import('../../components/index/IndReferral.vue' /* webpackChunkName: "components/ind-referral" */).then(c => wrapFunctional(c.default || c))
export const IndReviews = () => import('../../components/index/IndReviews.vue' /* webpackChunkName: "components/ind-reviews" */).then(c => wrapFunctional(c.default || c))
export const IndSafety = () => import('../../components/index/IndSafety.vue' /* webpackChunkName: "components/ind-safety" */).then(c => wrapFunctional(c.default || c))
export const IndSoloAds = () => import('../../components/index/IndSoloAds.vue' /* webpackChunkName: "components/ind-solo-ads" */).then(c => wrapFunctional(c.default || c))
export const IndTools = () => import('../../components/index/IndTools.vue' /* webpackChunkName: "components/ind-tools" */).then(c => wrapFunctional(c.default || c))
export const IndTopSellers = () => import('../../components/index/IndTopSellers.vue' /* webpackChunkName: "components/ind-top-sellers" */).then(c => wrapFunctional(c.default || c))
export const IndWhatMakesUdimiSoloAdsBetter = () => import('../../components/index/IndWhatMakesUdimiSoloAdsBetter.vue' /* webpackChunkName: "components/ind-what-makes-udimi-solo-ads-better" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
