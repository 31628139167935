import dayjs, { extend } from 'dayjs'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'

extend(duration)
extend(relativeTime)
const extendedDayjs = function (...args) {
    const date = dayjs(...args)
    // customOptions - is a properly with a list of completely custom options that do not belong to the dayjs
    // useCurrentDateWhenInvalid - allows to define whether to use the current date as teh fallback in case if a provided date is not valid and thus it is not possible to create a proper dayjs-instance with it
    const { useCurrentDateWhenInvalid = true } = args.find(item => item?.customOptions)?.customOptions || {}

    return date.isValid() || !useCurrentDateWhenInvalid ? date : dayjs([new Date(), args.filter((_, index) => index !== 0)])
}

Object.setPrototypeOf(extendedDayjs, dayjs)

export default function (_, inject) {
    inject('dayjs', extendedDayjs)
}
