import { watch } from '@vue/composition-api'
import config from './config'
import state, { libraryState } from './state'

export const loadGApi = new Promise((resolve) => {
    // @ts-ignore: To resolve errors in nuxt3
    const isRunningInBrowser = typeof process === 'undefined' || process.client === undefined || process.client

    if (!libraryState.apiLoadIntitited && isRunningInBrowser) {
        const script = document.createElement('script')
        libraryState.apiLoadIntitited = true
        script.addEventListener('load', () => {
            libraryState.apiLoaded = true
            resolve(window.google)
        })
        script.src = config.library
        script.async = true
        script.defer = true
        document.head.appendChild(script)
    }
})

export const mergeObjects = (obj1, obj2) => {
    const mergedObj = { ...obj1 }
    for (const key in obj2) {
        obj2[key] !== undefined &&
      obj2[key] !== null &&
      (mergedObj[key] = obj2[key])
    }
    return mergedObj
}

export const renderLoginButton = (
    idConfiguration,
    buttonRef,
    buttonConfig,
    hasSlot,
    error,
    google
) => {
    if (error) {
        const callback = idConfiguration.callback
        idConfiguration.callback = (response) => {
            if (!response.credential) {
                error(response)
            } else {
                callback && callback(response)
            }
        }
    }
    google.accounts.id.initialize(idConfiguration)
    const button = buttonRef.value
    if (button) {
        !hasSlot && google.accounts.id.renderButton(button, buttonConfig)
    }
}

/**
 * A wrapper function which makes sure google Client Library is loaded and then give an access to the SDK api
 * @param action A function to execute some actions only after google Client Library is loaded
 */
export const googleSdkLoaded = (action) => {
    if (!libraryState.apiLoadIntitited) {
        loadGApi.then((google) => {
            action(google)
        })
    } else if (!libraryState.apiLoaded) {
        watch(
            () => libraryState.apiLoaded,
            (loaded) => {
                loaded && action(window.google)
            }
        )
    } else {
        action(window.google)
    }
}

export const onMount = (
    idConfiguration,
    buttonRef,
    options,
    hasSlot
) => {
    if (!idConfiguration.client_id) {
        throw new Error(
            'Prop client id required since plugin is not initialized with a client id'
        )
    }
    googleSdkLoaded((google) => {
        renderLoginButton(
            idConfiguration,
            buttonRef,
            options.buttonConfig,
            hasSlot,
            options.error,
            google
        )
        options.prompt && checkAvailableGoogleOneTap(options) &&
        googleOneTap({
            clientId: options.clientId,
            callback: options.callback,
            error: options.error,
            autoLogin: options.autoLogin
        })
    })
}

/**
 * A helper function to trigger login popup using google.accounts.oauth2.initCodeClient function under the hoods
 * @param options Optionally you can add clientId in this option if not initialized on plugin install
 * @returns A promise which get resolved with an auth code once user login through the popup
 */
export const googleAuthCodeLogin = (options) => {
    return new Promise((resolve, reject) => {
        googleSdkLoaded((google) => {
            if ((!options || !options.clientId) && !state.clientId) {
                throw new Error(
                    'clientId is required since the plugin is not initialized with a Client Id'
                )
            }
            google.accounts.oauth2
                .initCodeClient({
                    client_id: (options && options.clientId) || state.clientId || '',
                    scope: config.scopes,
                    ux_mode: 'popup',
                    callback: (response) => {
                        if (response.code) {
                            resolve(response)
                        } else {
                            reject(response)
                        }
                    }
                })
                .requestCode()
        })
    })
}

/**
 * A helper function to trigger login popup using google.accounts.oauth2.initTokenClient function under the hoods
 * @param options Optionally you can add clientId in this option if not initialized on plugin install
 * @returns A promise which get resolved with an access token once user login through the popup
 */
export const googleTokenLogin = (options) => {
    return new Promise((resolve, reject) => {
        googleSdkLoaded((google) => {
            if ((!options || !options.clientId) && !state.clientId) {
                throw new Error(
                    'clientId is required since the plugin is not initialized with a Client Id'
                )
            }
            google.accounts.oauth2
                .initTokenClient({
                    client_id: (options && options.clientId) || state.clientId || '',
                    scope: config.scopes,
                    callback: (response) => {
                        if (response.access_token) {
                            resolve(response)
                        } else {
                            reject(response)
                        }
                    }
                })
                .requestAccessToken()
        })
    })
}
/**
 * A function to open one-tap and automatic log-in prompt
 * @param options Options to customise the behavior of one-tap and automatic log-in prompt
 * @returns A promise which get resolved once user login through the prompt
 */
export const googleOneTap = (
    options
) => {
    !options && (options = {})
    if (!options.clientId && !state.clientId) {
        throw new Error('clientId is required')
    }

    const idConfig = { use_fedcm_for_prompt: true }
    options.clientId && (idConfig.client_id = options.clientId)
    !options.clientId && state.clientId && (idConfig.client_id = state.clientId)
    options.context && (idConfig.context = options.context)
    options.autoLogin !== undefined && (idConfig.auto_select = options.autoLogin)
    options.cancelOnTapOutside !== undefined &&
    (idConfig.cancel_on_tap_outside = options.cancelOnTapOutside)
    return new Promise((resolve, reject) => {
        idConfig.callback = (response) => {
            options && options.callback && options.callback(response)
            if (response.credential) {
                resolve(response)
            } else {
                reject(response)
            }
        }
        googleSdkLoaded((google) => {
            google.accounts.id.initialize(idConfig)
            google.accounts.id.prompt((notification) => {
                options &&
                options.onNotification &&
                options.onNotification(notification)
                // handlePromptError({
                //     notification,
                //     reject,
                //     error: options && options.error
                // })
            })
        })
    })
}
const checkAvailableGoogleOneTap = (options) => {
    const { pageName, pagePath, noPromptOnPages } = options
    if (noPromptOnPages?.length) {
        if (noPromptOnPages.find(page => page.name === pageName || page.path === pagePath)) {
            return false
        }
    }
    return true
}
/**
 * This will make user to login and select account again by disabling auto select
 */
export const googleLogout = () => {
    googleSdkLoaded((google) => {
        google.accounts.id.disableAutoSelect()
    })
}
