//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, ref, onMounted, useSlots } from '@vue/composition-api'
import { useRoute, useStore, useContext } from '@nuxtjs/composition-api'
import { AUTH_MODE_DEVELOPMENT, REDIRECT_URLS } from '~/constants/auth'
import state, { libraryState } from '~/plugins/google-auth/state'
import * as utils from '~/plugins/google-auth/utils'
import { CLIENT_TIMEZONE } from '~/constants'

export default defineComponent({
    name: 'ElGoogleAuth',
    props: {
        /** Your Google API client ID, to create one [follow these steps](https://developers.google.com/identity/gsi/web/guides/get-google-api-clientid) */
        clientId: {
            type: String,
            required: true
        },
        /** IdConfiguration object for initializing, see list of fields and descriptions of the IdConfiguration [here](https://developers.google.com/identity/gsi/web/reference/js-reference#IdConfiguration) */
        // idConfiguration: Object,
        /** Configuration of the login button rendered by Google, see list of fields and descriptions of these configurations [here](https://developers.google.com/identity/gsi/web/reference/js-reference#GsiButtonConfiguration) */
        buttonConfig: {
            type: Object,
            default: () => ({
                width: 304,
                height: 44
            })
        },
        // /** Callback function to triggered on successfull login */
        // callback: Function,
        /** Callback function to triggered on popup returning with invalid response or when the one-tap prompt fails */
        // error: Function,
        /** To show the button google login */
        button: {
            type: Boolean,
            default: false
        },
        /** To show the One-tap and Automatic-Login prompt */
        prompt: {
            type: Boolean,
            default: false
        },
        /** Pages where prompt should not be shown */
        noPromptOnPages: {
            type: Array,
            default: () => [
                { name: 'login' },
                { name: 'signup' },
                { name: 'forgot' },
                { name: 'restore-token' },
                { name: 'u-hash' },
                { name: 'u-settings-hash' }
            ]
        },
        /** Boolean value showing whether the google client library is loaded or not */
        autoLogin: {
            type: Boolean,
            default: false
        },
        /** Type of popup, if set to 'code' will give an Auth code in the popup call back and if set to 'token' the popup callback will give as an access token */
        popupType: {
            type: String,
            default: 'TOKEN'
        }
    },
    setup (props) {
        const buttonRef = ref(null)
        const route = useRoute()
        const store = useStore()
        const slots = useSlots()
        const { $api, $handleErrors, $alert, $config, $fingerprint, $cookies } = useContext()
        const hasSlot = !!slots.default
        // return
        const { path, name } = route.value
        const options = utils.mergeObjects(state, props)
        options.pagePath = path
        options.pageName = name
        options.callback = callback

        const idConfiguration = {
            use_fedcm_for_prompt: true,
            client_id: options.clientId || null,
            auto_select: options.autoLogin || false,
            callback: options.callback,
            ...options.idConfiguration
        }

        const openPopup = (type) => {
            if (type === 'TOKEN') {
                utils.googleTokenLogin({ clientId: options.clientId }).then((response) => {
                    options.callback && options.callback(response)
                })
            } else {
                utils
                    .googleAuthCodeLogin({ clientId: options.clientId })
                    .then((response) => {
                        options.callback && options.callback(response)
                    })
            }
        }

        const promo = store.getters['cookies/promo']
        const tsource = store.getters['cookies/tsource']
        const fullUrl = store.getters['cookies/fullUrl']
        let callbackIsRun = false

        function callback (response) {
            if (!callbackIsRun) {
                callbackIsRun = true
                store.commit('setIsShowGreyBox', true)
                $api.post('guests/auth/google', {
                    token: response.credential,
                    fp_token2: $fingerprint,
                    timezone: CLIENT_TIMEZONE,
                    promo_uid: promo.uid,
                    promo_sid: promo.sid,
                    promo_url: promo.uid && fullUrl.uid === promo.uid ? fullUrl.referrer : '',
                    tsource_uid: tsource.uid,
                    tsource_optin: tsource.optin,
                    tsource_date: tsource.date,
                    is_discount: !!$cookies.get(store.state.constants.local.discountBoxCookieName),
                    saved_request: route.value.params?.savedRequest
                })
                    .then((res) => {
                        if ($config.authMode === AUTH_MODE_DEVELOPMENT) {
                            store.dispatch('cookies/setAuthCookie', {
                                id: res.data.id,
                                email: res.data.login,
                                token: res.data.token,
                                fingerprint: res.data.fingerprint
                            })
                        }
                        const serverRedirect = res.data.redirect && REDIRECT_URLS[res.data.redirect]
                            ? REDIRECT_URLS[res.data.redirect]
                            : REDIRECT_URLS.mypage
                        window.location.href = route.value.params?.redirect || serverRedirect
                    })
                    .catch((err) => {
                        callbackIsRun = false
                        $handleErrors(err, (_message) => {
                            $alert(_message)
                        })
                        store.commit('setIsShowGreyBox', false)
                    })
            }
        }

        onMounted(() => {
            utils.onMount(idConfiguration, buttonRef, options, hasSlot)
        })

        return {
            options,
            hasSlot,
            openPopup,
            buttonRef,
            libraryState,
            idConfiguration
        }
    }
})
