import { keysToCamel } from '~/utils/helpers'

export const state = () => ({
    sections: [],
    initData: {},
    defaultPostsSortingOptions: {
        attribute: 'date',
        direction: 'desc'
    },
    isCurrentlyActiveTopicLoading: false,
    currentlyActiveTopic: {},
    currentlyActiveFirstComment: {}
})

export const mutations = {
    setSections (state, payload) {
        state.sections = payload
    },
    setInitData (state, payload) {
        state.initData = payload
    },
    setDefaultPostsSortingOptions (state, value) {
        state.defaultPostsSortingOptions = value
    },
    setCurrentlyActiveTopic (state, value) {
        state.currentlyActiveTopic = value
    },
    setCurrentlyActiveFirstComment (state, value) {
        state.currentlyActiveFirstComment = value
    },
    setIsCurrentlyActiveTopicLoading (state, value) {
        state.isCurrentlyActiveTopicLoading = value
    }
}

export const actions = {
    initForum ({ commit }) {
        return this.$api.get('guests/forum/sections/list')
            .then((res) => {
                const data = keysToCamel(res.data)
                commit('setSections', data || [])
                return data
            })
            .catch(err => this.$handleErrors(err))
    },
    updateSection ({ getters, commit }, { id, data }) {
        commit('setSections', getters.sections.map(section => section.id === id ? data : section))
    },
    fetchTopicById (_, { id }) {
        return this.$api.get(`/guests/forum/posts/view/${id}`)
            .then(res => keysToCamel(res.data))
            .catch(err => this.$handleErrors(err))
    },
    async initActiveTopicByIdSlug ({ commit }, { idSlug }) {
        commit('setIsCurrentlyActiveTopicLoading', true)
        const id = idSlug?.split('-')[0]
        try {
            const request = await this.$api.get(`/guests/forum/posts/init/${id}`)
            const data = keysToCamel(request.data)
            commit('setCurrentlyActiveTopic', keysToCamel(request.data).post)
            commit('setCurrentlyActiveFirstComment', keysToCamel(request.data).commentStarter)

            return data
        } catch (err) {
            this.$handleErrors(err)
        } finally {
            commit('setIsCurrentlyActiveTopicLoading', false)
        }
    },
    updateCurrentlyActiveTopic ({ commit }, value) {
        commit('setCurrentlyActiveTopic', value)
    },
    updateCurrentlyActiveFirstComment ({ commit }, value) {
        commit('setCurrentlyActiveFirstComment', value)
    }
}

export const getters = {
    sectionByUid: ({ sections }) => uid => (sections || []).find(section => section?.uid === uid),
    sections: ({ sections }) => sections,
    defaultPostsSortingOptions: ({ defaultPostsSortingOptions }) => defaultPostsSortingOptions,
    currentlyActiveTopic: ({ currentlyActiveTopic }) => currentlyActiveTopic,
    currentlyActiveFirstComment: ({ currentlyActiveFirstComment }) => currentlyActiveFirstComment,
    createCommentLink: () => comment => comment.isStarter
        ? {
            name: 'forum-section-idSlug',
            params: {
                section: comment.sectionUid,
                idSlug: `${comment.postId || comment.post?.id}-${comment.postSlug || comment.post?.uid}`
            }
        }
        : {
            name: 'forum-comment-id',
            params: {
                id: comment.id,
                section: comment.sectionUid,
                idSlug: `${comment.postId || comment.post?.id}-${comment.postSlug || comment.post?.uid}`
            }
        }
}
