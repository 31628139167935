import { getBaseDomain } from '~/utils/helpers'

export default class PromoCookie {
    uid = null
    date = null
    sid = null

    constructor (value) {
        value && this.setEncodedValue(value)
    }

    static get cookieName () {
        return 'udimipromo'
    }

    getEncodedValue () {
        return [this.uid, this.date, this.sid].join('|')
    }

    setEncodedValue (value) {
        const data = value?.split('|') || []
        this.uid = data?.[0] || null
        this.date = data?.[1] || null
        this.sid = data?.[2] || null
    }

    static setup (ctx, arg) {
        if (!arg.uid || !ctx.$legacyCookies) {
            return false
        }

        const cookie = new this()
        cookie.setEncodedValue(ctx.$legacyCookies.get(cookie.constructor.cookieName))

        // do not set self cookie
        if (arg.user && arg.user.uid && arg.user.uid === arg.uid) {
            return false
        }

        // set values
        cookie.uid = arg.uid
        cookie.sid = arg.sid && typeof arg.sid === 'string' ? arg.sid.substring(0, 20) : null
        // YYYY-MM-DD HH:mm:ss
        cookie.date = Intl.DateTimeFormat('sv-SE', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            timeZone: 'UTC'
        }).format(new Date())

        // set the cookie
        ctx.$legacyCookies.set(cookie.constructor.cookieName, cookie.getEncodedValue(), {
            maxAge: 3600 * 24 * 180,
            domain: getBaseDomain(ctx.$config.siteHost)
        })

        return cookie
    }
}
