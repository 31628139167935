import { getBaseDomain } from '~/utils/helpers'

const MAX_LENGTH = 10

export default class UniqueCookie {
    uids = []

    constructor (value) {
        value && this.setEncodedValue(value)
    }

    static get cookieName () {
        return 'udimiunique'
    }

    getEncodedValue () {
        return this.uids.join(',')
    }

    setEncodedValue (value) {
        this.uids = value ? value.split(',') : []
    }

    static setup (ctx, arg) {
        if (!arg.uid || !ctx.$legacyCookies) {
            return false
        }
        const cookie = new this()
        cookie.setEncodedValue(ctx.$legacyCookies.get(cookie.constructor.cookieName))

        // do not set the cookie if uid is already in the list
        if (cookie.uids.includes(arg.uid)) {
            return false
        }

        // limit the length
        if (cookie.uids.length >= MAX_LENGTH) {
            cookie.uids.splice(0, cookie.uids.length - MAX_LENGTH + 1)
        }

        // add new value
        cookie.uids.push(arg.uid)

        // set the cookie
        ctx.$legacyCookies.set(cookie.constructor.cookieName, cookie.getEncodedValue(), {
            maxAge: 3600 * 24 * 180,
            domain: getBaseDomain(ctx.$config.siteHost)
        })
        return cookie
    }
}
