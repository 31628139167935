export default (_ctx, inject) => {
    const alert = (options) => {
        if (typeof options !== 'object') {
            options = { text: options }
        }
        window.$nuxt.$emit('dialog-open', options)
        return new Promise((resolve, reject) => {
            window.$nuxt.$off('dialog-confirm').$on('dialog-confirm', (params) => {
                return resolve(params)
            })
            window.$nuxt.$off('dialog-cancel').$on('dialog-cancel', (params) => {
                return reject(params)
            })
        })
    }
    const confirm = (options) => {
        if (typeof options !== 'object') {
            options = { text: options }
        }
        return alert({ ...options, isConfirm: true })
    }
    inject('alert', alert)
    inject('confirm', confirm)
}
