import { REDIRECT_URLS } from '~/constants/auth'

export default function ({ redirect, store, $config, route }) {
    if (store.getters['cookies/auth'].token &&
        !['affiliate-prime', 'affiliate-regular'].includes(route.name) &&
        !route.query.noautologin
    ) {
        const url = store.state.memberRedirect && REDIRECT_URLS[store.state.memberRedirect]
            ? REDIRECT_URLS[store.state.memberRedirect]
            : REDIRECT_URLS.mypage
        redirect($config.siteProtocol + '://' + $config.maHost + url)
    }
}
