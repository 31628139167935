import { getBaseDomain } from '~/utils/helpers'

export default class FullUrlCookie {
    uid = null
    referrer = null

    constructor (value) {
        value && this.setEncodedValue(value)
    }

    static get cookieName () {
        return 'reffullurlid'
    }

    getEncodedValue () {
        return [this.uid, this.referrer].join('|')
    }

    setEncodedValue (value) {
        const data = value?.split('|', 2) || []
        this.uid = data?.[0] || null
        this.referrer = data?.[1] || null
    }

    static setup (ctx, arg) {
        if (!arg.uid || !ctx.$legacyCookies) {
            return false
        }
        const cookie = new this()
        cookie.setEncodedValue(ctx.$legacyCookies.get(cookie.constructor.cookieName))
        cookie.uid = arg.uid
        cookie.referrer = arg.referrer && typeof arg.referrer === 'string' ? arg.referrer.substring(0, 512) : ''

        // set the cookie
        ctx.$legacyCookies.set(cookie.constructor.cookieName, cookie.getEncodedValue(), {
            maxAge: 3600 * 24 * 180,
            domain: getBaseDomain(ctx.$config.siteHost)
        })
        return cookie
    }
}
